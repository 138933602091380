.social_media {
  display: flex;
  justify-content: space-between;
}
.social_media svg {
  font-size: 1.5rem;
  color: #303030;
}
.social_media.dark svg {
  color: #fff;
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .social_media svg {
    font-size: 1rem;
  }
}
