.section {
  margin: 60px auto;
  width: fit-content;
}
.title {
  font-family: var(--neue-light-font);
  font-size: 1.2rem;
  margin: 20px;
  text-align: center;
}

.subscriber_form {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.subscriber_input {
  width: 280px;
}
.subscriber_input input {
  width: 90%;
}
.subscriber_input label {
  font-size: 0.8rem;
}
.subscriber_input.dark input {
  border-bottom: 1px solid #fff;
}
.subscriber_input.dark label {
  color: #fff;
}

.subscriber_submit {
  margin-left: -20px;
  margin-bottom: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 2;
}
.social_media {
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
}
.social_media svg {
  font-size: 1.5rem;
  color: #303030;
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
}
