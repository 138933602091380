.footer {
  padding: var(--margin-layout-container);
}
.footer.dark {
  background-color: #000;
  color: #fff;
}
.list_footer_content {
  margin: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.section_footer_content_row {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.section_footer_content_column {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
}
.section_footer_content_column div {
  width: fit-content;
}
.mobile_footer {
  display: flex;
  justify-content: space-between;
  margin: 100px 0 50px;
}
.mobile_footer .social_media {
  width: 40%;
}
.payment_list {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.payment_list .img {
  margin-top: 10px;
  margin-right: 10px;
}

.payment_list .img img {
  filter: grayscale();
  width: 60px;
  height: auto;
  object-fit: contain;
}

.payment_list .img img:hover {
  filter: none;
}
.payment_img.dark {
  mix-blend-mode: luminosity;
  filter: invert(1) !important;
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .footer {
    padding: var(--container-mobile);
  }
  .payment_list {
    width: 100%;
  }
}
